import { AppSettings } from './shared/app.settings';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    hideFooter = false;

    constructor(private translate: TranslateService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private router: Router, private route: ActivatedRoute) {

        iconRegistry.addSvgIcon('library', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_library.svg'));
        iconRegistry.addSvgIcon('img', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/icon_img.svg'));

        translate.setDefaultLang(AppSettings.APP_DEFAULT_LANGUAGE);


    }

    ngOnInit() {
        this.router.events.subscribe(x => {
            if (x instanceof NavigationEnd) {
                this.hideFooter = this.route.root.firstChild.snapshot.data['hideFooter'];
            }
        }
        );
    }

    private rootRoute(route: ActivatedRoute): ActivatedRoute {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    preventDefaultEvent(event) {
        event.preventDefault();
    }
}
