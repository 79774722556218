export class AppSettings {

    static readonly APP_DEFAULT_LANGUAGE = 'de';

    static readonly APP_PREFIX : string = 'cntr-seat-';
    static readonly APP_LOGGIN_PREFIX : string = 'seat-';
    static readonly DOMAIN : string = 'root.media_library.';
    static readonly DEFAULT_DOMAIN : string = 'root.media_library.';
    static readonly DOMAIN2 : string = 'root.';
    static readonly WORKFLOW_ID : number = 300;
    static readonly USER_STORAGE : string = "LoggedInProfile";
    static readonly OC_SITE : string = "seat";

    //Workflows
    static readonly WORKFLOW_WEBUSER : number = 60000;
    static readonly WORKFLOW_PROJECT : number = 300;
    static readonly WORKFLOW_ACTION : number =  17000;

    static readonly WORKFLOW_ID_PICTURE : number = 20;
    static readonly WORKFLOW_ID_VIDEO : number = 50;
    static readonly WORKFLOW_ID_PDF : number = 4005;
    static readonly WORKFLOW_ID_AUDIO : number = 15;
    static readonly WORKFLOW_ID_BRIEFING : number = 9051;
    static readonly WORKFLOW_ID_OFFICE : number = 1120;
    static readonly WORKFLOW_ID_PRESENTATION : number = 1130;
    static readonly WORKFLOW_ID_ZIP : number = 65;
    static readonly WORKFLOW_ID_WEBPAGE : number = 20;
    static readonly WORKFLOW_ID_SPREADSHEET : number = 5065;

    //GET DATA
    static readonly LIMIT : number = 100;

    //Mails
    static readonly MAIL_PENDINGMATERIALS : string = 'pendingMaterials';
    static readonly MAIL_NEWMATERIAL : string = 'newMaterial';
    static readonly MAIL_NOMATERIALACTION : string = 'noMaterialsAction';
    static readonly MAIL_NOMATERIALACTIONAPPROVED : string = 'noMaterialsActionApproved';
    static readonly MAIL_NEWUSER : string = 'newUser';
    static readonly MAIL_NEWUSERADMINISTRATOR : string = 'newUserAdministrator';
    static readonly MAIL_STARTINAPPROVAL : string = 'startInApproval';
    static readonly MAIL_CONFIRMATION : string = 'confirmation';
    static readonly MAIL_MATERIALACTIONUPDATED : string = 'materialActionUpdated';
    static readonly MAIL_RECOVERY: string = 'recovery';
    static readonly MAIL_ACTION_PM: string = 'actionPM';
    static readonly MAIL_RECOVERY_CONFIRMATION: string = 'recoveryConfirmation';

    // Tracking
    static readonly ATI_TRACKING_ID: string = '';

    //SIDEBAR
    static readonly SIDEBAR_CONFIG = [
        {key: 'logo', visible: true},
        {key: 'dashboard', visible: false},
        {key: 'projects', visible: false},
        {key: 'jobs', visible: false},
        {key: 'assets', visible: true},
        {key: 'quickbutton', visible: false}
    ]

    static readonly DELETION_MARK_DELETION: string = '1';
    static readonly DELETION_NONE: string = '0';

    static readonly SHOW_ERRORS : boolean = false;

    static readonly TIME_TO_CANCEL: number = 180;

    static readonly DEFAULT_SECOND_DOMAIN_ROOT = 'root.';
    static readonly DEFAULT_SECOND_DOMAIN_USER = ["root."];
    static readonly LOGIN_BKG_TAG_ID = 15236;

    static readonly NUMBER_PAGES: number = 9;

    static readonly DAYS_CHECK_EXPIRE_SOON: number = 90;

    static readonly DOWNLOAD_VARIANT = [
        {   name: "original",
            keys: [
                {key: "downloadLink", mime: "mimetype"}
                ]
        },
        {   name: "thumbnail",
            keys: [
                {key: "thumbnail", mime: "mimeThumbnail"}
            ]
        }

    ]

    static readonly DOWNLOAD_RES = [ "30_small" ];
}