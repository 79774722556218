import { AppSettings } from 'app/shared/app.settings';
import { AuthUser, GeneratedKey } from 'app/shared/guard/model/auth.model';
import { Injectable } from "@angular/core";
import { createHash, randomBytes } from "crypto";

import * as bcryptjs from "bcryptjs";
import * as jwt from "jsonwebtoken";
import { HCMSService } from "app/shared/services/satellites/hcms.service";
import { ServiceConfiguration } from '../__old/model/Authentication';
import { JwtConfiguration } from '../__old/model/Accounts';

@Injectable({
    providedIn: 'root'
})
export class Authentication {

    public static PASSWORDHASH_MD5 = "md5";
    public static PASSWORDHASH_SHA1SALTED = "sha1s";
    public static PASSWORDHASH_BCRYPT = "bcrypt";ç
    private static PASSWORDHASH_BCRYPT_SALTROUNDS = 10;
    private static PASSWORDHASH_SEPARATOR = ":";

    private config: ServiceConfiguration;
    private jwt: JwtConfiguration;
    private passwordHash: string;

    constructor(private hcmsService:HCMSService){

        this.config = require("assets/conf/authentication.config.json");
        this.jwt = this.config.jwt;
        this.passwordHash = Authentication.PASSWORDHASH_BCRYPT;
        if (this.passwordHash !== Authentication.PASSWORDHASH_BCRYPT && this.passwordHash !== Authentication.PASSWORDHASH_SHA1SALTED) {
            throw "Invalid password hash method: " + this.passwordHash;
        }

    }

    public async validUser(login: string, password: string) {

        let user = await this.getUserByLogin(login);
        if (user) {
            if (await this.checkPassword(user.auth.password || "", password)) {
                let confirmedUser = Object.assign(new AuthUser, user);
                await this.createToken(confirmedUser);
                return confirmedUser;
            }
        }
    }

    public async getUserByLogin(login:string) : Promise<AuthUser> {
        return await this.hcmsService.get().one('entity/auth?query=auth.login="' + login + '"').get({}, {'Authorization': Authentication.getAuthToken()}).toPromise().then(data => {
            if (data.result && data.result.length > 0) {
                return data.result[0];
            }
        });
    }

    public async getUser(user) : Promise<any> {
        return await this.hcmsService.get().one('entity/auth', user.id).get({}, {'Authorization': Authentication.getAuthToken()}).toPromise();
    }

    public async updateUser(user) : Promise<AuthUser> {
        return user.put({}, {'Authorization': Authentication.getAuthToken()}).toPromise();
    }

    public async createNewKey() : Promise<GeneratedKey> {
        let generatedKey =  new GeneratedKey();
        generatedKey.key = (await createRandom(32)).toString('hex');
        generatedKey.timestamp =  new Date().toISOString();
        return generatedKey;
    }

    private async createToken(userInfo: AuthUser): Promise<string> {
        let roles = ['loggedUser'];
        let token = "Bearer " + jwt.sign({
            roles: roles.concat(userInfo.roles)
        }, this.jwt.hmacSecret, {
            subject: userInfo.id.toString(),
            algorithm: this.jwtHmacAlgorithm,
            expiresIn: this.jwt.expiration || 900
          });
        localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser', token);
        return token;
    }

    public static getAuthToken(): string {
        let config = require("assets/conf/authentication.config.json");
        let authorization = "Bearer "+ jwt.sign({
            roles: ['auth']
        }, config.jwt.hmacSecret, {
            algorithm: config.jwt.hmacAlgorithm || "HS256",
            expiresIn: config.jwt.expiration || 900
        });
        return authorization;
    }


    private get jwtHmacAlgorithm(): string {
        return this.jwt.hmacAlgorithm || "HS256";
    }

    public async checkPassword(passwordHash: string, password: string): Promise<boolean> {
        const idx = passwordHash.indexOf(Authentication.PASSWORDHASH_SEPARATOR);
        const method = idx > -1 ? passwordHash.substring(0, idx) : Authentication.PASSWORDHASH_MD5;
        const rawHash = idx > -1 ? passwordHash.substring(idx + 1) : passwordHash;
        switch (method) {
            case Authentication.PASSWORDHASH_BCRYPT:
                return await bcryptjs.compare(password, rawHash);
            case Authentication.PASSWORDHASH_SHA1SALTED:
                // random salt : hex encoded SHA-1 hash of UTF-8 password + UTF-8 salt
                const saltIdx = rawHash.indexOf(Authentication.PASSWORDHASH_SEPARATOR);
                if (saltIdx === -1) {
                    return false;
                }
                const salt = rawHash.substring(0, saltIdx);
                const sha1Hash = rawHash.substring(saltIdx + 1);
                const sha1PasswordHash = createHash("SHA1")
                    .update(Buffer.from(password, "UTF-8"))
                    .update(Buffer.from(salt, "UTF-8"))
                    .digest("hex");
                return sha1PasswordHash.toLowerCase() === sha1Hash.toLowerCase();
            case Authentication.PASSWORDHASH_MD5:
                // hex encoded MD5 of UTF-8 password
                const md5PasswordHash = createHash("MD5")
                    .update(Buffer.from(password, "UTF-8"))
                    .digest("hex");
                return md5PasswordHash.toLowerCase() === rawHash.toLowerCase();
            default:
                // unsupported password hash
                return false;
        }
    }

    public async hashPassword(password: string): Promise<string> {
        switch (this.passwordHash) {
            case Authentication.PASSWORDHASH_SHA1SALTED:
                const salt = randomBytes(8).toString("hex");
                const sha1Hash = createHash("SHA1")
                    .update(Buffer.from(password, "UTF-8"))
                    .update(Buffer.from(salt, "UTF-8"))
                    .digest("hex");
                return Authentication.PASSWORDHASH_SHA1SALTED + Authentication.PASSWORDHASH_SEPARATOR + salt + Authentication.PASSWORDHASH_SEPARATOR + sha1Hash;
            case Authentication.PASSWORDHASH_BCRYPT:
                return Authentication.PASSWORDHASH_BCRYPT + Authentication.PASSWORDHASH_SEPARATOR + await bcryptjs.hash(password, Authentication.PASSWORDHASH_BCRYPT_SALTROUNDS);
        }
        throw "Invalid password hash method: " + this.passwordHash;
    }
}


function createRandom(len: number):Promise<Buffer> {
    return new Promise<Buffer>((resolve, reject) => {
        randomBytes(len, (err, buf) => {
            if (err) {
                reject(err);
            }
            resolve(buf);
        })
    });
}