import { AuthUser, Auth, EmailStruct } from 'app/shared/guard/model/auth.model';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { HCMSService } from '../services/satellites/hcms.service';
import { LiquidCacheService } from 'ngx-liquid-cache';
import { Router } from '@angular/router';
import { CollectionsService } from '../services/collections/collections.service';
import { Authentication } from './authentication/Authentication';
import { AppSettings } from '../app.settings';
import { User } from '../model/user.model';
import { TranslateService } from '@ngx-translate/core';
import { OCService } from '../services/satellites/oc.service';
import { CommonService } from '../services/common/common.service';
import { RolesService } from '../services/user/roles.service';
import { Mail } from '../model/mail.model';
import { MailService } from '../services/mail/mail.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  redirectUrl: string;
  authentication: Authentication;

  constructor(
    private hcmsService: HCMSService,
    private ocService: OCService,
    private userService: UserService,
    private cache: LiquidCacheService,
    private router: Router,
    private collectionsService: CollectionsService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private mailService: MailService,
  ) {

    this.authentication = new Authentication(hcmsService);

  }

  login(login: string, password: string): Promise<string> {

    return this.authentication.validUser(login, password).then(user => {

      if (!user)
        return 'invalid_login_or_password';
      if (user.workflowStep && +user.workflowStep === UserService.WORKFLOW_WEBUSER_STEP_PENDING_TO_APPROVE) {
         return 'login_not_actived';
      }
      if (user.workflowStep && +user.workflowStep === UserService.WORKFLOW_WEBUSER_STEP_ACCOUNT_DISABLED) {
        return 'login_disabled';
      }
      if (user.confirmation || (user.workflowStep && +user.workflowStep === UserService.WORKFLOW_WEBUSER_STEP_NOT_CONFIRMED)) {
        return 'login_not_confirmed';
      }

      this.loginUserToSystem(user.id);
      return null;

    });

  }

  async validUser(login: string, password: string): Promise<any> {
    return this.authentication.validUser(login, password)
  }

  async resetPassword(login: string): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (user) {

      let current = await this.authentication.getUser(user);
      current.passwordRecovery = await this.authentication.createNewKey();

      let currentLang = this.commonService.getCurrentLanguage();
      let updated = await this.authentication.updateUser(current);
      if (updated) {

        let mail: Mail = new Mail(AppSettings.MAIL_RECOVERY + '_' + updated.id, AppSettings.MAIL_RECOVERY, -1, +updated.id);
        this.mailService.saveMail(mail, false);
        return 'recovery_password_email_sent';

      }
    }
    return 'recovery_password_invalid_email';
  }

  async confirmResetPassword(login: string, token: string, newPassword: string): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (!(user && user.passwordRecovery && user.passwordRecovery.key === token)) {
      return 'invalid_recovery_token';
    }

    let updated = await this.resetUserPassword(user, newPassword);
    if (updated) {
      return 'new_password_activated';
    }
  }

  async resetUserPassword(user: AuthUser, newPassword: string) {
    let current = await this.authentication.getUser(user);
    current.auth.password = await this.authentication.hashPassword(newPassword);

    if(current.passwordRecovery)
      delete current.passwordRecovery;

    return await this.authentication.updateUser(current);
  }

  async changeUserPassword(auth: AuthUser, newPassword: string) {
    return this.resetUserPassword(auth, newPassword).then(result => result);
  }

  async confirmUser(login: string, token: string): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (!(user && user['confirmation'] && user['confirmation'].key === token)) {
      return 'invalid_confirmation_token';
    }

    let current = await this.authentication.getUser(user);
    delete current.confirmation;
    current.workflowStep = UserService.WORKFLOW_WEBUSER_STEP_APPROVED;
    let updated = await this.authentication.updateUser(current);
    if (updated) {
      return 'user_actived';
    }
  }

  async register(login: string, password: string, firstName: string, lastName: string, company: string, domain2: string, gender: string): Promise<string> {
    const user: AuthUser = await this.authentication.getUserByLogin(login);
    if (user) {
      return 'registration_procees_error_duplicated';
    }

    let authData: Auth = new Auth();
    authData.login = login;
    authData.password = await this.authentication.hashPassword(password);

    let emailStruct: EmailStruct = new EmailStruct();
    emailStruct.email = login;

    let active = await this.isInWhitelist(login);

    let newUser: AuthUser = new AuthUser();
    newUser.auth = authData;
    newUser.emailStruct = emailStruct;
    newUser.confirmation = await this.authentication.createNewKey();
    newUser.name = firstName + ' ' + lastName;
    newUser.firstName = firstName;
    newUser.lastName = lastName;
    newUser.company = company;
    newUser.gender = gender;
    //newUser.domain = domain;
    newUser.domain2 = domain2;
    newUser.email = login;
    newUser.language = 'de';
    newUser.roles = [RolesService.DEFAULT_ROLE];
    newUser.active = active;
    newUser.activatedEmailSent = active;

    let updated: AuthUser = await this.hcmsService.get().all('entity/auth').post(newUser, {}, {'Authorization': Authentication.getAuthToken()}).toPromise();
    if (updated) {
      if (updated.active) return await this.sendConfirmationEmail(updated);
      return await this.sendActivationEmails(updated);
    }


    return 'registration_procees_error';
  }

  async isInWhitelist(email: string) : Promise<boolean> {

    let firstPart = email.substring(email.lastIndexOf("@"));
    let domain = firstPart.substring(0, firstPart.indexOf("."));

    let emails = await this.ocService.get().one('config_list/whitelist').get().toPromise();
    let emailsFull = await this.ocService.get().one('config_list/whitelist_full').get().toPromise();

    return emails.length === 0 || emails.plain().some(y => y.indexOf(domain) > -1) || emailsFull.plain().some(y => y.indexOf(email) > -1);

  }

  async sendActivationEmails(updated: AuthUser) : Promise<string> {
    let mail: Mail = new Mail(AppSettings.MAIL_NEWUSER + '_' + updated.id, AppSettings.MAIL_NEWUSER, -1, +updated.id);
    this.mailService.saveMail(mail, false);

    this.userService.getAdminsMailing().subscribe(data => {

      if (data.result) {
        data.result.forEach(admin => {
          let mailAdmin: Mail = new Mail(AppSettings.MAIL_NEWUSERADMINISTRATOR + '_' + updated.id + '_' + admin.id, AppSettings.MAIL_NEWUSERADMINISTRATOR, +updated.id, +admin.id);
          this.mailService.saveMail(mailAdmin, false);
        });
      }
    });

    return 'registration_procees_success_need_active';
  }

  async sendConfirmationEmail(updated: AuthUser){

    let mail: Mail = new Mail(AppSettings.MAIL_CONFIRMATION + '_' + updated.id, AppSettings.MAIL_CONFIRMATION, -1, +updated.id);
    this.mailService.saveMail(mail, false);
    return 'registration_procees_success';
  }

  checkLoggedIn() {
    return this.userService.getCurrentUser() !== null;
  }

  logout() {
    this.removeUserAndCachedData();
    this.router.navigate(['/login']);
  }

  removeUserAndCachedData() {
    this.userService.removeCurrenUser();
    this.collectionsService.removeUserCollection();
    localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');
    // localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + 'jwt');
    this.cache.remove('webusers');
    //this.cache.remove('partyusers');
    this.cache.remove('main_domain');
    this.cache.remove('domains');
    this.cache.remove('domains2');

    // remove for now, as they have changed - maybe keep later for performance...?
    this.cache.remove('workflows');
    this.cache.remove('workflowsSteps');
    this.cache.remove('roles');

    Object.keys(this.cache.cachedElements).filter(x => x.startsWith('userLinks')).forEach(x => {
      this.cache.remove(x);
    });

    // Remove persistent filters
    Object.keys(this.cache.cachedElements).filter(x => x.indexOf('##') >= 0).forEach(x => {
      this.cache.remove(x);
    });

  }

  private loginUserToSystem(id) {

    this.hcmsService.get().one('entity/webuser', id).get().toPromise()
      .then((data: User) => {
        if (data != null) {

          let user: User = Object.assign(new User, this.hcmsService.get().copy(data).plain());
          this.userService.setCurrenUser(user);

          if (!user.collection) {
            this.collectionsService.createCollection(user.id);
          }

          if (user.language) {
            this.translateService.use(user.language);
          }

          this.userService.setCurrenUser(user);
          //setTimeout(() =>
            this.router.navigate([this.redirectUrl ? this.redirectUrl : '/'])
          //, 500);

        }
      });

  }

}
