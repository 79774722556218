import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsComponent } from 'app/shared/components/notifications/notifications.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  static readonly DEFAULT_DURATION : number = 2500;
  static readonly DEFAULT_PANEL_CLASS : string = 'success-snack';
  static readonly DEFAULT_VERTICAL_POSITION: any = 'top';
  static readonly DEFAULT_HORIZONTAL_POSITION: any = 'start'

  constructor(private snackbar: MatSnackBar, private translateService: TranslateService) { }

  show(message:string, link: Array<string> = null,
    duration: number = NotificationsService.DEFAULT_DURATION,
    panelClass: string = NotificationsService.DEFAULT_PANEL_CLASS,
    verticalPosition: any = NotificationsService.DEFAULT_VERTICAL_POSITION,
    horizontalPosition: any = NotificationsService.DEFAULT_HORIZONTAL_POSITION ) {

    let data = {message:message};
    if (link) {
      data['link'] = link;
    }

    return this.snackbar.openFromComponent(NotificationsComponent, {
      data: data,
      duration: duration,
      panelClass: panelClass,
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
     });
  }
}
