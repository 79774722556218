import { Asset } from "./asset.model";

export class User extends Asset {

    firstName: string;
    lastName: string;
    gender: string;
    email: string;
    party: string;
    initials: string;
    language: string;
    watchlist: Array<string> = [];
    roles: string[] = [];
    collection: number;

    pictures: UserPicture[] = [];
    mainPicture: UserPicture;

    display_name: string;
}

export class UserPicture {

    id: number;
    name: string;
    type: string;
    domain: string;
    downloadLink: string;
    reloadPreview: boolean = false;
    version: number;
    image: string;

}