import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './shared/material/material.module';

import { FooterComponent } from './shared/footer/footer.component';
import { NgxLiquidCacheModule, LiquidCacheConfig, LiquidCacheStorageTypes } from 'ngx-liquid-cache';
import { environment } from 'environments/environment';
import { RestangularModule } from 'ngx-restangular';
import { UserService } from './shared/services/user/user.service';
import { WorkflowService } from './shared/services/workflows/workflow.service';
import { AppSettings } from './shared/app.settings';

import { NgxImageZoomModule } from 'ngx-image-zoom';
import { User } from './shared/model/user.model';
import { ErrorsComponent } from './errors/errors.component';
import { ErrorsService } from './shared/services/errors/errors.service';
import { GlobalErrorHandler } from './shared/errors/global.error';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

import { LightboxModule } from 'ngx-lightbox';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

import { DatePipe } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material';
import { PaginatorI18n } from './shared/model/PaginatorI18n';

// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const liquidCacheConfig: LiquidCacheConfig = {
    storageType: LiquidCacheStorageTypes.localStorage,
    localStoragePrefix: AppSettings.APP_PREFIX
};

export function RestangularConfigFactory(RestangularProvider) {

    RestangularProvider.setBaseUrl(environment.baseHCSMUrl);

    RestangularProvider.setDefaultHeaders({ 'Access-Control-Allow-Origin': true });
    RestangularProvider.setFullRequestInterceptor(function (element, operation, route, url: string, headers, params, httpConfig) {

        let currentUser: User = JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + AppSettings.USER_STORAGE));
        if (currentUser) {
            if (operation == 'put') {
                let currentTime = new Date();
                if (element instanceof FormData) {
                    let entity: any = element.get('entity');
                    entity = JSON.parse(entity);

                    entity.modifiedByNew = +currentUser.id;
                    entity.cntr_modified = currentTime.toISOString();
                    element.set('entity', JSON.stringify(entity));
                } else {
                    element.modifiedByNew = +currentUser.id;
                    element.cntr_modified = currentTime.toISOString();
                }
            }
            if (operation == 'post') {
                let currentTime = new Date();
                if (element instanceof FormData) {
                    let entity: any = element.get('entity');
                    entity = JSON.parse(entity);

                    entity.modifiedByNew = +currentUser.id;
                    entity.createdByNew = +currentUser.id;
                    entity.cntr_modified = currentTime.toISOString();
                    element.set('entity', JSON.stringify(entity));
                } else {
                    element.modifiedByNew = +currentUser.id;
                    element.createdByNew = +currentUser.id;
                    element.cntr_modified = currentTime.toISOString();
                }
            }
        }


        if (url.indexOf(environment.baseOCUrl) < 0) {

            let excludeCalls = ['/entity/view?query=idtags'];
            var jwtUser = localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');

            if (jwtUser && !excludeCalls.find(x => url.indexOf(x) > -1)) {
                headers.Authorization = jwtUser;
            }
        } else {
            headers = {
                'Access-Control-Allow-Origin': true
            };
            if (environment.ocSatelliteAccess) {
                headers.Authorization = environment.ocSatelliteAccess;
            }
        }

        let customParams = {};
        if (environment.env) customParams['environment'] = environment.env;
        if (AppSettings.OC_SITE) customParams['site'] = AppSettings.OC_SITE;
        customParams['lang'] = 'de';

        params = Object.assign({}, params, customParams);

        return {
            element: element,
            params: params,
            headers: headers,
            httpConfig: httpConfig
        };
    });
    RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {

        ErrorsService.instance.showHttpErrorResponse(response);
        if (response.url && response.url.indexOf(environment.baseHCSMUrl) > -1 && response.status === 401) {
            localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + 'jwtUser');
            (window as any).location = "/login?expired=true";
        }
        return true;
    });
}

@NgModule({
    declarations: [AppComponent, FooterComponent, ErrorsComponent, NotificationsComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        OverlayModule,
        HttpClientModule,
        FlexLayoutModule.withConfig({ addFlexToParent: false }),
        MaterialModule,
        RestangularModule.forRoot(RestangularConfigFactory),
        NgxLiquidCacheModule.forRoot(liquidCacheConfig),
        LightboxModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            isolate: true
        }),
        NgxImageZoomModule.forRoot()
    ],
    entryComponents: [ErrorsComponent, NotificationsComponent],
    providers: [UserService, WorkflowService, ErrorsService, DatePipe,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: MatPaginatorIntl, deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
