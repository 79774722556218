import { Injectable } from '@angular/core';
import { HCMSService } from '../satellites/hcms.service';
import { Collection } from 'app/shared/model/collection.model';
import { UserService } from '../user/user.service';
import { AppSettings } from 'app/shared/app.settings';
import { Media } from 'app/shared/model/media.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {

  private COLLECTION_STORAGE: string = "LoggedInCollection";

  constructor(private hcmsService: HCMSService, private usersService: UserService) { }

  public getCollectionByUser(userid) {
    return this.hcmsService.get().one('entity/collection?query=user=' + userid + '%26markDeletion!=1').get().toPromise();
  }

  public loadUserCollection() {
    let currentUser = this.usersService.getCurrentUser();

    if (currentUser) {
      this.getCollectionByUser(currentUser.id).then(data => {
        if (data.result && data.result.length > 0) {
            this.updateUserCollection(data.result.filter(x => !x.markForDeletion));
        } else {
          this.createCollection(currentUser.id);
        }
      });
    }

  }

  async getUserCollection() {
    let collections = JSON.parse(localStorage.getItem(AppSettings.APP_LOGGIN_PREFIX + this.COLLECTION_STORAGE));
    if (!collections) {
      let currentUser = this.usersService.getCurrentUser();
      const result = await this.getCollectionByUser(currentUser.id);

      if (result) {
        collections = result.result;
      } else {
        collections = [];
      }
    }

    return collections;
  }

  updateUserCollection(collection) {
    localStorage.setItem(AppSettings.APP_LOGGIN_PREFIX + this.COLLECTION_STORAGE, JSON.stringify(collection));
  }

  removeUserCollection() {
    localStorage.removeItem(AppSettings.APP_LOGGIN_PREFIX + this.COLLECTION_STORAGE);
  }

  public getCollectionById(id) {
    return this.hcmsService.get().one('entity/collection', id).get().toPromise();
  }

  createCollection(userid, name = 'Sammlung') {
    let collection = new Collection();
    collection.name = name;
    collection.user = +userid;

    delete collection.created;
    delete collection.createdAttr;

    return this.hcmsService.get().all('entity/collection').post(collection).toPromise().then(result => {
      if (result) {
        let user = this.usersService.getCurrentUser();
        user.collection = result.id;
        this.usersService.updateCurrentUser(user);

        return result;
      }
    });

  }

  addAssetsToCollection(assets: Array<Number>, collection: Collection) {
    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      assets.forEach(asset => {
        if (!col.assets.includes(asset)) {
          col.assets.push(asset);
        }
      });

      return col.put().toPromise();
    });
  }

  removeAssetsFromCollection(assets: Array<Number>, collection: Collection) {

    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      assets.forEach(asset => {
        const index = col.assets.indexOf(asset);
        if (index > -1) {
          col.assets.splice(index, 1);
        }
      });

      //col.removeRelation = +asset.id;

      return col.put().toPromise();
    });
  }

  removeAssetFromCollection(asset: Media, collection: Collection) {
    return this.getCollectionById(collection.id).then(col => {
      if (!col.assets) {
        col.assets = [];
      }

      const index = col.assets.indexOf(asset.id);
      if (index > -1) {
        col.assets.splice(index, 1);
      }

      col.removeRelation = +asset.id;

      return col.put().toPromise();
    });
  }

  deleteCollection(collection: Collection) {

    return this.getCollectionById(collection.id).then(col => {
      col.markForDeletion = true;

      return col.put().toPromise();

    });
  }

  async saveCollection(collection: Collection) {
    let newCollection = Object.assign(new Collection, this.hcmsService.get().copy(collection).plain());
    delete newCollection.assets;

    let updateCollection = await this.hcmsService.get().one('entity/collection', newCollection.id).get().toPromise();
    updateCollection = Object.assign(updateCollection, newCollection);

    return updateCollection.save().toPromise();
  }


}
