import { Asset } from "./asset.model";

export class Mail extends Asset {
    usage: string;
    asset: number;
    user: number;
    materials: Array<number>;

    constructor(name: string = null, usage: string = null, asset: number = 0, user: number = 0) {
        super();

        this.name = name;
        this.usage = usage;
        this.asset = asset;
        this.user = user;
        if (asset != 0)
        this.materials = [asset];
    }
}
