import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { ErrorsService } from '../services/errors/errors.service';
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private errorsService: ErrorsService) { }

  handleError(error) {


    this.errorsService.showInternalError(error);

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    //throw error;
    // throwError(error);
  }

}